import { get, post } from './base/request.js'


/**
 * @func 搜索影视数据
 *
 * @param {int} searchKey 搜搜字段
 * @param {dict} filter { media_type, country, year, genre, order_by }
 * @return {[Object]} 电影对象列表
 */
export function searchMediaService(searchKey, filter, callback, error) {

    var url = "/search/" + searchKey

    get(url, filter)
    .then(function (response) {
        if ("function" == typeof callback) {
            callback(response);
        }
    })
    .catch(function (errorResponse) {
        if ("function" == typeof error) {
            error(errorResponse)
        }
    })

}
