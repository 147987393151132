import { get, post } from './base/request.js'


/**
 * @func 获取首页数据
 *
 * @return {[Object]} Home Data
 */
export function fetchHomeDataService(callback, error) {

    var url = "/home/"

    get(url)
    .then(function (response) {
        if ("function" == typeof callback) {
            callback(response);
        }
    })
    .catch(function (errorResponse) {
        if ("function" == typeof error) {
            error(errorResponse)
        }
    })

}


/**
 * @func 获取热门电影
 *
 * @return {[Object]} 热门电影
 */
 export function fetchHomeHotMovieService(callback, error) {

    var url = "/home/hot/movie/"

    get(url)
    .then(function (response) {
        if ("function" == typeof callback) {
            callback(response);
        }
    })
    .catch(function (errorResponse) {
        if ("function" == typeof error) {
            error(errorResponse)
        }
    })

}
