<template>
    <div id="list-page">
        <section class="ant-layout">
            <header class="ant-layout-header">
                <Head></Head>
            </header>

            <main class="ant-layout-content" style="background-color: #161619;">
                <div class="search-result-content" style="padding-top:50px;">
                    <div class="layout-main">
                        <div v-for="item in searchResult.data" :key="item.id" >
                            <MovieListView
                                :movieData="item"
                                @clickMovie="clickMovie">
                            </MovieListView>
                        </div>
                    </div>
                    <div class="layout-side">
                        <div class="pmf-search-hot">
                            <span style="font-size: 20px;line-height: 1;color: white;">全网电影热搜榜</span>
                            <div class="pmf-search-ranklist">
                                <div v-for="(item, index) in hotMovieTop10.hotMovies" :key="item.title" class="ranklist" >
                                    <a class="rank-movie-title" :href="'/play/movie/'+item.uniqueId" target="_blank">
                                    <span v-if="index==0" class="rank-one">{{index+1}} </span>
                                    <span v-else-if="index==1" class="rank-two">{{index+1}} </span>
                                    <span v-else-if="index==2" class="rank-three">{{index+1}} </span>
                                    <span v-else class="rank-other">{{index+1}} </span>
                                    <span > {{item.title}}</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <footer class="ant-layout-footer">
                <Foot></Foot>
            </footer>
        </section>
    </div>
</template>

<script>

import Head from '@/components/common/Head'
import Foot from '@/components/common/Foot'
import MovieListView from '@/components/common/MovieListView'
import { searchMediaService } from '@/services/searchService'
import { fetchHomeHotMovieService } from '@/services/homeService.js'


export default {
    name: 'ListPage',
    head: {
        title: "搜索结果 - 熊猫侠",
        meta:[
            {
                name: 'description',
                content: '熊猫侠(www.pandaman.tv), 致力于打造海外华人最大的在线视频平台, 以高清的视频内容，流畅的视频体验.',
            },
            {
                name: 'keywords',
                content: '熊猫侠,视频,视频分享,视频搜索,视频播放,视频社区,海外视频,电影,电视剧,真人秀,美剧,动漫,综艺,华人,影院',
            }
        ]
    },
    data() {
        return {
            searchResult: null,
            hotMovieTop10: null,
            pagination: {
                onChange: page => {
                    console.log(page);
                },
                pageSize: 10,
            }
        }
    },
    components:{
        Head,
        Foot,
        MovieListView,
    },
    watch:{
        //监控url params 变化 更新页面数据
        '$route.params.searchString': function (searchString) {
            this.getSearchResult(searchString)
        }
    },
    methods:{
        clickMovie(selectedMovieId) {
            /**
            * @func 点击电影响应
            *
            * @param {Int} selectedMovieId 电影ID
            */

            window.removeEventListener('scroll', this.scrollBottom)
            let routeData = this.$router.resolve({
                name: 'Play',
                params: {
                    uniqueId: selectedMovieId
                }
            });
            window.open(routeData.href, '_blank');
        },
        getSearchResult(searchString){
            /**
            * @func 获取搜索结果
            *
            * @param {String searchString 搜索内容
            */

            // 异步请求search result数据
            searchMediaService(searchString, null, response => {
                this.searchResult = response
            }), error => {
                // 错误响应
            }
        },
        /**
         * @func 抓取热门电影数据
         */
        fetchHotMovieData() {


            // TODO: 热门电影 top 10
            fetchHomeHotMovieService(response => {
                console.log("hot movie top 10")
                console.log(response.data)
                this.hotMovieTop10 = response.data
            }, error => {
                // 异常处理
            })
        }

    },
    mounted () {
        // 获取URL中搜索searchString
        var searchString = this.$route.params.searchString
        this.getSearchResult(searchString)
        this.fetchHotMovieData()
    }
}

</script>
