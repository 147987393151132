<template>
    <div class="pmf-search-result-item">
        <div class="result-figure">
            <!-- <img
                width="180"
                height="236"
                :src="movieData.images.s_cover"
                @click="clickMovie()"
            /> -->
            <MovieThumbView
                :movieData="movieData"
                @clickMovie="clickMovie"
                >
            </MovieThumbView>
        </div>
        <div class="result-right">
            <h3 class="pmf-search-result-title">
                <span class="main-title" @click="clickMovie()">{{movieData.title}}</span>
            </h3><br>

            <span v-for="director in movieData.directors" :key="director">
                导演 : {{ director }}
            </span><br>
            {{movieData.countries}}<br>
            {{movieData.outline}} <br>
            <button @click="clickMovie()">立即播放</button>
        </div>
    </div>
</template>

<script>
import MovieThumbView from '@/components/common/MovieThumbView'

export default {
    name: 'MovieListView',
    data() {
        return {
        };
    },
    props: ["movieData"],
    components:{
        MovieThumbView,
    },
    methods: {
        /**
         * @func thumbnail点击响应
         * 调用父亲响应
         */
        clickMovie: function() {
            this.$emit('clickMovie', this.movieData.uniqueId)
        }
    }
};
</script>

<style>


</style>
